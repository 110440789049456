import React, { useEffect } from 'react';
import copy from 'copy-to-clipboard';

import WarningService from '../../services/WarningService';

import './Toolbar.scss';

const Toolbar = (props: any): JSX.Element => {
  const exitGame = () => {
    props.storeGameData('');
  };

  useEffect(() => {
    //console.log('>>>> Toolbar', Date());
  }, [props.gameCode]);

  const copyCode = (code: string) => {
    copy(code);
    WarningService.changeStatus('Game code [' + code + '] has copied to clipboard successfully');
  }

  return (
    <div className="toolbar" style={{ backgroundColor: '#' + props.playerColor }}>
      <div className="left_pnl">
        <span className="game_code" onClick={() => copyCode(props.gameCode)}>
          <h5>Share this code to add friends</h5>
          {props.gameCode}
        </span>
      </div>
      <div className="right_pnl">
        <button className="close_btn" onClick={exitGame}><span></span></button>
      </div>
    </div>
  );
}

export default Toolbar;
