import React, { useState, useEffect } from "react";

import ClientService from "../services/ClientService";
import LoaderService from "../services/LoaderService";
import WarningService from "../services/WarningService";

import Storage from "../data/Storage";

import "./Start.scss";

const Start = (props: any): JSX.Element => {
  const [gameCodeInput, setGameCodeInput] = useState<string>("");

  useEffect(() => {
    //console.log('>>>> Start', Date());
  });

  const startGame = (): void => {
    LoaderService.changeStatus(true);

    ClientService.startGame()
      .then((data: any) => {
        if (data.error) {
          throw new Error(JSON.stringify(data.error));
        }

        props.storeGameData(data.data);

        LoaderService.changeStatus(false);
      })
      .catch((error: any) => {
        WarningService.changeStatus(error.message);
        LoaderService.changeStatus(false);
      });
  };

  const joinGame = (): void => {
    LoaderService.changeStatus(true);

    let lastHex = Storage.load(gameCodeInput) || null;

    ClientService.joinGame(gameCodeInput, lastHex)
      .then((data: any) => {
        if (data.error) {
          throw new Error(JSON.stringify(data.error));
        }

        props.storeGameData(data.data, lastHex);

        LoaderService.changeStatus(false);
      })
      .catch((error: any) => {
        WarningService.changeStatus(error.message);
        LoaderService.changeStatus(false);
      });
  };

  const doEnter = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      joinGame();
    }
  };

  return (
    <>
      <div className="starter_form">
        <div
          className="logo"
          style={{ backgroundImage: `url('../res/img/logo.png')` }}
        ></div>
        <button
          onClick={() => startGame()}
          disabled={gameCodeInput ? true : false}
        >
          Start New
        </button>
        <span>OR</span>
        <div className="group_txt_btn">
          <input
            placeholder="Enter game code"
            onChange={(e) => setGameCodeInput(e.target.value)}
            onKeyDown={(e) => doEnter(e)}
            value={gameCodeInput}
          />
          <button
            onClick={() => joinGame()}
            disabled={gameCodeInput ? false : true}
          >
            Join
          </button>
        </div>
        <p>
          Remember to wait for others to join once you have shared the code
          before rolling the dice, as they won't be able to participate
          otherwise. :)
        </p>
      </div>
    </>
  );
};

export default Start;
