import superagent from 'superagent';
import Storage from '../data/Storage';

const applyAuthHeader = function (req) {
  return req.set({
    'Authorization': `Bearer ${Storage.load('token')}`,
    //...deviceInfos,
  });
};

const applyUniversalErrorHandler = function (req) {
  return req.catch(({ response, status, message: statusText }) => {
    let error = new Error('Error occurred');

    switch (status) {
      case 400:
        console.error(statusText || response.body.message);
        break;
      case 401:
        //Globals.logout();
        console.error(statusText + ' - ' + JSON.parse(response.text)['error']);
        break;
      case 403:
      case 404:
      case 422:
      default:
        console.warn(
          'unknown error，maybe caused by cors unsupported or invalid configuration of server',
          response
        );
        break;
    }

    return Object.assign(error, response);
  });
};

export default class BaseService {
  _applyMiddlewares(agent) {
    return agent.use(applyAuthHeader).use(applyUniversalErrorHandler);
  }

  _agentFactory(url, payload, { type = 'json', method = 'get' }) {
    url = `https://${process.env.REACT_APP_API_URL}/${url}`;
    method = method.toLocaleLowerCase();

    let agent;

    if (method === 'get' || !superagent[method]) {
      agent = superagent
        .get(url)
        .type(type)
        .query(payload);
    } else {
      agent = superagent[method](url)
        .type(type)
        .send(payload);
    }

    return agent;
  }

  get(url, query, options) {
    return this._applyMiddlewares(
      this._agentFactory(url, query, {
        ...options,
        method: 'get',
      })
    );
  }

  post(url, data, options) {
    return this._applyMiddlewares(
      this._agentFactory(url, data, {
        ...options,
        method: 'post',
      })
    );
  }

  put(url, data, options) {
    return this._applyMiddlewares(
      this._agentFactory(url, data, {
        ...options,
        method: 'put',
      })
    );
  }

  patch(url, data, options) {
    return this._applyMiddlewares(
      this._agentFactory(url, data, {
        ...options,
        method: 'patch',
      })
    );
  }

  delete(url, data, options) {
    return this._applyMiddlewares(
      this._agentFactory(url, data, {
        ...options,
        method: 'delete',
      })
    );
  }

  upload(url, fd) {
    return this.post(url, fd, {
      type: null,
      method: 'post',
    });
  }
}
