import React, { useState, useEffect, useCallback } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';

import Start from './components/Start.tsx';
import Board from './components/Board.tsx';
import Chatter from './components/Chatter.tsx';

import Loader from './components/tools/Loader.tsx';
import Warnings from './components/tools/Warnings.tsx';

import Storage from './data/Storage';

import './App.scss';

function App() {
  const [isTaller, setIsTaller] = useState(window.innerHeight > window.innerWidth ? true : false);
  const [gameCode, setGameCode] = useState(localStorage.getItem("gameCode") || '');
  const [playerColor, setPlayerColor] = useState('');
  const [playerCount, setPlayerCount] = useState(0);
  const [currentPlayerColor, setCurrentPlayerColor] = useState('000000');
  const [socketUrl, setSocketUrl] = useState(null);
  const [messageHistory, setMessageHistory] = useState([]);

  const [activePlayers, setActivePlayers] = useState({});

  const [diceBoardVisible, setDiceBoardVisible] = useState(false);
  const [diceCubeVal, setDiceCubeVal] = useState(0);
  const [diceCubeColor, setDiceCubeColor] = useState('00000');

  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, { onOpen: (ws) => /*console.log('WS Opened', ws)*/ null, shouldReconnect: (closeEvent) => true });

  useEffect(() => {
    //console.log('>>>> App', Date());

    function handleResize() {
      if (window.innerWidth > (window.innerHeight - 200)) {
        setIsTaller(false);
      } else {
        setIsTaller(true);
      }
    }

    if (gameCode !== '' && playerColor !== '') {
      setSocketUrl(`wss://${process.env.REACT_APP_API_URL}/${gameCode}/${playerColor}`);
    } else {
      setSocketUrl(null);
      setMessageHistory([]);
      setDiceCubeVal(0);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [gameCode, playerColor, diceCubeVal, setMessageHistory]);

  useEffect(() => {
    if (lastMessage !== null && lastMessage.isTrusted) {
      const messageJSON = JSON.parse(lastMessage.data);

      const updateActivePlayers = (data) => {
        if (data.split(',').length !== Object.keys(activePlayers).length) {
          setActivePlayers(() => {
            let players = {};

            data.split(',').forEach((color) => {
              let colorAndValue = color.split('|');
              players[colorAndValue[0]] = { 'cell': colorAndValue[1], 'total': colorAndValue[2], 'next_cell': colorAndValue[1], 'value': 0, 'left_loc': `${Math.random() * 40}`, 'top_loc': `${Math.random() * 40}` };
            });

            return { ...players };
          });
        }
      };

      if (messageJSON.type === "CHAT_ENTRY") {
        setMessageHistory((prev) => prev.concat({ 'sender': messageJSON.sender, 'msg': messageJSON.message }));
      } else if (messageJSON.type === "SYSTEM_MSG") {
        //console.log(messageJSON);

        if (messageJSON.message === "WS_CLIENT_COUNT") {
          setPlayerCount(messageJSON.count);
          updateActivePlayers(messageJSON.colors);
        } else if (messageJSON.message === "WS_CLIENT_DISCONNECT") {
          setPlayerCount(messageJSON.count);
          updateActivePlayers(messageJSON.colors);
          setCurrentPlayerColor(messageJSON.color);
        } else if (messageJSON.message === "DICE_ROLLED") {
          if (activePlayers[messageJSON.playerColor] && activePlayers[messageJSON.playerColor].cell !== messageJSON.currentCell) {
            setDiceCubeVal(messageJSON.lastDice);
            setDiceCubeColor(messageJSON.playerColor);
            setDiceBoardVisible(true);

            setTimeout(function () {
              setDiceBoardVisible(false);
            }, 500);

            setActivePlayers(players => {
              return {
                ...players,
                [messageJSON.playerColor]: { 'cell': messageJSON.currentCell, 'total': messageJSON.currentTotal, 'next_cell': messageJSON.nextCell, 'value': messageJSON.lastDice, 'left_loc': activePlayers[messageJSON.playerColor].left_loc, 'top_loc': activePlayers[messageJSON.playerColor].top_loc },
              };
            });
          }

          setCurrentPlayerColor(messageJSON.nextPlayerColor);
        }
      }
    }
  }, [lastMessage, activePlayers]);

  const sendChatMsg = useCallback((message) => sendMessage(message), [sendMessage]);

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Un-instantiated',
  }[readyState];

  const storeGameData = (data, lastHex) => {
    if (data === '') {
      setGameCode('');
      setPlayerColor('');
      setActivePlayers({});
    } else {
      setGameCode(data.boardId);
      setPlayerColor(data.color);

      setCurrentPlayerColor(data.activePlayer);

      Storage.save(data.boardId, data.color);
    }
  }

  /*
    todo: known issues
    - go out > try new > go back to old (seeing old and new both buttons)
  */

  return (
    <div className={`app_holder ${isTaller ? 'taller_mode' : 'wider_mode'}`}>
      <div className="App">
        <Loader />

        {gameCode === '' && <Start storeGameData={storeGameData} />}
        {gameCode !== '' && <Board storeGameData={storeGameData} gameCode={gameCode} playerColor={playerColor} activePlayers={activePlayers} currentPlayerColor={currentPlayerColor} />}

        {gameCode !== '' && <Chatter sendChatMsg={sendChatMsg} messages={messageHistory} status={connectionStatus} playerColor={playerColor} playerCount={playerCount} />}
        <Warnings />

        {diceBoardVisible && diceCubeVal > 0 &&
          <div className='dice_board'>
            <span style={{ color: '#' + diceCubeColor }}>
              {diceCubeVal}
            </span>
          </div>
        }
      </div>
    </div>
  );
}

export default App;
