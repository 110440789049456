import React, { useState, useEffect } from 'react';
import LoaderService from '../../services/LoaderService';
import './Loader.scss';

const Loader = (props: any): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    let service: any;

    service = LoaderService.loaderStatus.subscribe(state => {
      setIsLoading(state);
    });

    return () => {
      service.unsubscribe();
    }
  });

  return (
    <div className={`loader_background ${isLoading ? 'show_loader' : 'no_loader'}`}>
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Loader;