class Storage {
  isValidJSON(value) {
    try {
      const result = JSON.parse(value);
      if (result && typeof result === 'object') return true;
    } catch (err) {
      return false;
    }
  }

  save(name, value, options) {
    const valueToStore =
      typeof value === 'object' ? JSON.stringify(value) : value;

    if (options && options.secure) {
      const secures = JSON.parse(localStorage.getItem('secures')) || [];

      if (secures.indexOf(name) === -1) secures.push(name);

      localStorage.setItem('secures', JSON.stringify(secures));
      sessionStorage.setItem(name, valueToStore);
    } else {
      localStorage.setItem(name, valueToStore);
    }
  }

  load(name) {
    const value = localStorage.getItem(name) || sessionStorage.getItem(name);
    
    if (this.isValidJSON(value)) {
      return JSON.parse(value);
    }
    return value;
  }

  remove(name) {
    localStorage.removeItem(name);
    sessionStorage.removeItem(name);
  }
}

export default new Storage();
