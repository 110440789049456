import { BehaviorSubject } from 'rxjs';
import Globals from '../data/Globals'

class WarningService {
  warning = new BehaviorSubject(null);
  warningMessage = this.warning.asObservable();

  changeStatus(nextStatus) {
    if (Globals.isJson(nextStatus) && (typeof (JSON.parse(nextStatus)) === 'string')) {
      nextStatus = JSON.parse(nextStatus);
    } else if (Globals.isJson(nextStatus) && (typeof (JSON.parse(nextStatus)) === 'object')) {
      nextStatus = Object.values(JSON.parse(nextStatus));
    }

    this.warning.next(nextStatus);
  }
}

export default new WarningService();