import React, { useState, useEffect, useRef } from "react";

// @ts-ignore
import Toolbar from "./controls/Toolbar.tsx";

import ClientService from "../services/ClientService";
import LoaderService from "../services/LoaderService";
import WarningService from "../services/WarningService";

import "./Board.scss";

const Board = (props: any): JSX.Element => {
  const [colorCodes, setColorCodes] = useState<string[]>([]);
  const [colorsGenerated, setColorsGenerated] = useState<string[]>([]);

  const playersRef = useRef([]);

  interface SnkLdr {
    from: number[];
    to: number[];
    size: number[];
    src: string;
  }

  const [snakeSet, setSnakeSet] = useState<SnkLdr[]>([]);
  const [ladderSet, setLadderSet] = useState<SnkLdr[]>([]);
  const [seed, setSeed] = useState<string>("");

  const ROWS = 10;
  const COLS = 10;

  const [lftPanelPlayers, setLftPanelPlayers] = useState<string[]>([]);
  const [rgtPanelPlayers, setRgtPanelPlayers] = useState<string[]>([]);

  useEffect(() => {
    //console.log('>>>> Board', Date());

    if (!colorsGenerated.length) {
      let cellColors: string[] = [];

      for (let i: number = 0; i < ROWS * COLS; i++) {
        cellColors.push(
          "rgba(" +
            Math.floor(Math.random() * 200) +
            ", " +
            Math.floor(Math.random() * 200) +
            ", " +
            Math.floor(Math.random() * 200) +
            ",1)"
        );
      }

      setColorsGenerated(cellColors);
    }

    setRgtPanelPlayers([]);
    setLftPanelPlayers([]);

    let i = 0;

    for (const [key] of Object.entries(props.activePlayers)) {
      if (i % 2) {
        setRgtPanelPlayers((prev) => prev.concat(key));
      } else {
        setLftPanelPlayers((prev) => prev.concat(key));
      }

      i++;
    }

    setColorCodes(colorsGenerated);
  }, [
    props.gameCode,
    props.activePlayers,
    colorsGenerated,
    props.currentPlayerColor,
  ]);

  useEffect(() => {
    LoaderService.changeStatus(true);

    ClientService.getBoardInfo(props.gameCode)
      .then((data: any) => {
        if (data.error) {
          throw new Error(JSON.stringify(data.error));
        }

        setSnakeSet(data.data.snakeSet);
        setLadderSet(data.data.ladderSet);
        setSeed(data.data.seed);

        LoaderService.changeStatus(false);
      })
      .catch((error: any) => {
        WarningService.changeStatus(error.message);
        LoaderService.changeStatus(false);
      });
  }, [props.gameCode]);

  const createCells = (r: number, n: number) => {
    let cells: JSX.Element[] = [];

    for (let i: number = n; i >= 1; i--) {
      let value: number = r * n - i + 1;

      cells.push(
        <div
          className="board_cell"
          key={value}
          style={{ backgroundColor: colorCodes[value - 1] + "" }}
        >
          <span>{value}</span>
        </div>
      );
    }

    return cells;
  };

  const createRows = (n: number): JSX.Element[] => {
    let rows: JSX.Element[] = [];

    for (let i: number = n; i >= 1; i--) {
      rows.push(
        <div className="board_row" key={i}>
          {createCells(i, COLS)}
        </div>
      );
    }

    return rows;
  };

  const putSnakes = (): JSX.Element[] => {
    let snakes: JSX.Element[] = [];

    snakeSet.forEach((snake, i) => {
      let xPos: number = parseInt(seed[i * 2]);
      let yPos: number = parseInt(seed[i * 2 + 1]);

      const styles: React.CSSProperties = {
        backgroundImage: `url('../res/img/${snake.src}.png')`,
        width: snake.size[0] * 10 + "%",
        height: snake.size[1] * 10 + "%",
        top: yPos * 10 + "%",
        left: xPos * 10 + "%",
      };

      snakes.push(<div className="snake" key={i} style={styles}></div>);
    });

    return snakes;
  };

  const putLadders = (): JSX.Element[] => {
    let ladders: JSX.Element[] = [];

    ladderSet.forEach((ladder, i) => {
      let xPos: number = parseInt(seed[i * 2 + 12]);
      let yPos: number = parseInt(seed[i * 2 + 13]);

      const styles: React.CSSProperties = {
        backgroundImage: `url('../res/img/${ladder.src}.png')`,
        width: ladder.size[0] * 10 + "%",
        height: ladder.size[1] * 10 + "%",
        top: yPos * 10 + "%",
        left: xPos * 10 + "%",
      };

      ladders.push(<div className="ladder" key={i} style={styles}></div>);
    });

    return ladders;
  };

  const rollDice = () => {
    LoaderService.changeStatus(true);

    ClientService.rollDice(props.gameCode, props.playerColor)
      .then((data: any) => {
        if (data.error) {
          throw new Error(JSON.stringify(data.error));
        }

        //console.log('ROLLED', data);

        LoaderService.changeStatus(false);
      })
      .catch((error: any) => {
        WarningService.changeStatus(error.message);
        LoaderService.changeStatus(false);
      });
  };

  const getBtnPlace = (code: string, cell: string): any => {
    if (cell === "WON") {
      return { left: `0%`, top: `0%` };
    } else if (cell === "0") {
      return { left: `0%`, top: `100%` };
    } else {
      let cellStr = ("000" + cell).substr(-3);

      let x = parseInt(cellStr.substring(0, 2));
      let y = parseInt(cellStr.charAt(cellStr.length - 1));

      return { left: `${x * 10}%`, top: `${y * 10}%` };
    }
  };

  return (
    <>
      <Toolbar
        storeGameData={props.storeGameData}
        setPlayerColor={props.setPlayerColor}
        gameCode={props.gameCode}
        playerColor={props.playerColor}
      />

      <div className="board_holder">
        <div className="player_lists player_lists_left">
          <div className="player_buttons">
            {lftPanelPlayers.map((player) => (
              <span
                className={
                  props.currentPlayerColor === player ? "" : "inactive"
                }
                key={player}
                style={{
                  backgroundColor: "#" + player,
                  borderColor: "#" + player,
                }}
              ></span>
            ))}
          </div>
        </div>

        <div className="board">
          <div className="cells">{createRows(ROWS)}</div>
          <div className="ladders">{putLadders()}</div>
          <div className="snakes">{putSnakes()}</div>
          <div className="players">
            {Object.keys(props.activePlayers).map((data) => (
              <div
                className="player_location"
                key={data}
                style={getBtnPlace(data, props.activePlayers[data].cell)}
              >
                <span
                  ref={(el) => (playersRef.current[data] = el?.style)}
                  style={{
                    backgroundColor: "#" + data,
                    left: props.activePlayers[data].left_loc + "%",
                    top: props.activePlayers[data].top_loc + "%",
                  }}
                ></span>
              </div>
            ))}
          </div>
        </div>

        <div className="player_lists player_lists_right">
          <div className="player_buttons">
            {rgtPanelPlayers.map((player) => (
              <span
                className={
                  props.currentPlayerColor === player ? "" : "inactive"
                }
                key={player}
                style={{
                  backgroundColor: "#" + player,
                  borderColor: "#" + player,
                }}
              ></span>
            ))}
          </div>
        </div>
      </div>

      <div className="roll_dice">
        <button
          onClick={rollDice}
          disabled={
            props.currentPlayerColor === props.playerColor ? false : true
          }
        >
          {props.currentPlayerColor === props.playerColor
            ? props.activePlayers[props.playerColor] &&
              props.activePlayers[props.playerColor].total > 99
              ? "Pass Turn"
              : "Roll Dice"
            : "Waiting"}
        </button>
      </div>
    </>
  );
};

export default Board;
