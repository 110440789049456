import BaseService from './BaseService';

class ClientService extends BaseService {
  startGame() {
    return this.get('game/start').then(
      res => res.body
    );
  }

  joinGame(gameCode, lastHex) {
    return this.post('game/join', { code: gameCode, hex: lastHex }).then(
      res => res.body
    );
  }

  getBoardInfo(gameCode) {
    return this.get(`game/boardinfo/${gameCode}`).then(
      res => res.body
    );
  }

  rollDice(gameCode, playerColor) {
    return this.get(`game/rolldice/${gameCode}/${playerColor}`).then(
      res => res.body
    );
  }
}

export default new ClientService();
