export const CustomWarnings = {
  'FILL_ALL': 'Please fill in the all fields',
  'FILL_REQUIRED': 'Please fill in the required fields',
  'PASSWORD_MATCH': 'Your password and confirmation password do not match',
}

export default class Globals {
  static isValidEmail(email) {
    const emailPatten = /^[a-zA-Z0-9.!#$%&’*/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return emailPatten.test(email);
  }

  static replaceNull(value, replacement = '') {
    return value === null ? replacement : value;
  }

  static isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
}
