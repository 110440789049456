import React, { useState, useEffect } from 'react';
import WarningService from '../../services/WarningService';
import './Warnings.scss';

let timer: any;

const Warnings = (props: any): JSX.Element => {
  const [warningMessage, setWarningMessage] = useState<string>(null);
  const [readyToDissapear, setReadyToDissapear] = useState<boolean>(true);

  useEffect(() => {
    let service: any;

    service = WarningService.warningMessage.subscribe((msg: any) => {
      clearTimeout(timer);

      setWarningMessage(msg);

      if (warningMessage) {
        timer = setTimeout(() => {
          setReadyToDissapear(true);
        }, 100);
      }
    });

    window.addEventListener('click', handleMouseClick);

    return () => {
      service.unsubscribe();
      window.removeEventListener('click', handleMouseClick);
    };
  });

  const handleMouseClick = () => {
    if (readyToDissapear) {
      closeWarning();
    }
  }

  const closeWarning = () => {
    clearTimeout(timer);

    WarningService.changeStatus("");
    setWarningMessage(null);
    setReadyToDissapear(false);
  }

  return (
    <div className={`warning_background ${warningMessage ? 'show_warnings' : 'no_warnings'}`}>
      {
        Array.isArray(warningMessage) ? warningMessage.map((value, i) => {
          return <li key={i}>{value}</li>
        }) : warningMessage
      }
      <div className="close" onClick={() => closeWarning()}><span></span></div>
    </div>
  );
};

export default Warnings;
