import React, { useState, useEffect, useRef } from 'react';

import './Chatter.scss';

const Chatter = (props: any): JSX.Element => {
  const [message, setMessage] = useState<string>('');
  const [showChatWindow, setShowChatWindow] = useState(false);
  const [allMsgCount, setAllMsgCount] = useState(0);
  const [seenMsgCount, setSeenMsgCount] = useState(0);

  const chatBottomRef = useRef(null);

  useEffect(() => {
    //console.log('>>>> Chatter', Date());

    setAllMsgCount(props.messages.length);

    chatBottomRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [props.messages]);

  const sendChatMsg = () => {
    props.sendChatMsg(message);
    setMessage('');
  }

  const doEnter = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      sendChatMsg();
    }
  }

  const changeChatVisibility = (status: boolean) => {
    setShowChatWindow(status);

    setSeenMsgCount(props.messages.length);
  }

  const validURL = (str: string) => {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
  }

  const isImage = (url: string) => {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
  }

  //todo: limit msg length

  //todo: upload images or files

  //todo: share code together with url

  return (
    <div>
      <div className={`chatter_btn ${showChatWindow ? 'hide' : 'show'}`} onClick={() => changeChatVisibility(true)}>
        <span><b>{allMsgCount - seenMsgCount}</b></span>
      </div>
      <div className={`chatter_box ${showChatWindow ? 'show' : 'hide'}`}>
        <div className="status">
          Chat is <b>{props.status}</b> ({props.playerCount} user online)
          <div className="hide_btn" onClick={() => changeChatVisibility(false)}><span></span></div>
        </div>
        <div className="messages">
          {props.messages.map((message: { msg: string, sender: string }, idx: number) => {
            let messageOutput: JSX.Element = <span className='plain_txt'>{message.msg}</span>;
            // Try to format text preview
            if (validURL(message.msg)) {
              if (isImage(message.msg)) {
                //todo: check if img actually loaded
                messageOutput = <a className='image_preview' href={message.msg} target='_blank' rel="noopener noreferrer"><img src={message.msg} alt='Click to preview'/></a>;
              } else {
                messageOutput = <a className='pure_link' href={message.msg} target='_blank' rel="noopener noreferrer">{message.msg}</a>;
              }
            }
            return <span key={idx} className={props.playerColor === message.sender ? 'yours' : 'others'}><span style={{ backgroundColor: '#' + message.sender }}>{messageOutput}</span></span>
          })}
          <div ref={chatBottomRef} />
        </div>
        <div className="message">
          <div className='group_txt_btn'>
            {
              props.status.toLowerCase() === 'open' &&
              <span>
                <input placeholder='Message here' onChange={e => setMessage(e.target.value)} onKeyDown={e => doEnter(e)} value={message} />
                <button onClick={() => sendChatMsg()} disabled={message ? false : true}>Send</button>
              </span>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Chatter;
