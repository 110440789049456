import { BehaviorSubject } from 'rxjs';

class LoaderService {
  loader = new BehaviorSubject(null);
  loaderStatus = this.loader.asObservable();

  changeStatus(nextStatus) {
    this.loader.next(nextStatus);
  }
}

export default new LoaderService();